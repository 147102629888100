<template>
  <div class="modal fade" ref="mdlSeleccionArticulo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Selección del articulo</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <filtrador
            v-on:dato-actualizado="
              dato = $event,
              refrescarArticulos()
            "
            :datoini="dato"
            placeholder="Divisió y/o Descripción"
          ></filtrador>
          <br>
          <div class="table-responsive">
            <table id="tabla-articulos" class="table table-hover table-striped table-nowrap" style="min-height: 350px;">
              <thead>
                <tr>
                  <th style="width:46px"></th>
                  <th style="width: 70px">#ID</th>
                  <th>Nombre</th>
                  <th style="width: 140px" class="text-center">Seleccionar</th>
                </tr>
              </thead>
              <tbody id="cpes">
                <tr v-for="articulo in articulos" :key="`art-${articulo.id}`">
                  <td>
                    <div style="min-height: 30px; display: inline-block">
                      <img
                        :src="`${API}/articulos/${articulo.id}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                        style="width:30px; height:30px;"
                        v-if="articulo.foto_principal"
                      />
                      <img
                        :src="inventarioIcon"
                        style="width:30px; height:30px;"
                        v-if="!articulo.foto_principal"
                      />
                    </div>
                  </td>
                  <td>{{articulo.id}}</td>
                  <td>{{articulo.nombre}}</td>
                  <td>
                    <button
                      @click="seleccionarArticulo(articulo)"
                      class="btn btn-outline-success btn-sm">
                      <i class="mdi mdi-check"></i>
                      Seleccionar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center" v-if="ta >= 10">
            <paginador
              :pag="page"
              :totpag="totpag"
              :epp="epp"
              @cargar-pagina="cargarPagina"
              @epp-actualizado="
                epp = $event,
                refrescarArticulos()
              "
            ></paginador>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Modal from 'bootstrap/js/dist/modal'
import Filtrador from '@/components/Filtrador.vue'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import Paginador from '@/components/Paginador.vue'
import {APIINV as API} from '@/API.js'

export default {
  components: {
    Filtrador,
    Paginador
  },
  data: function() {
    return {
      API : API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      dato: '',
      articulos: [],
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.mdlSeleccionarArticulos.por_pagina') || 10),
      ta: 0, // Total de Articulos
      totpag: 0, // Total de páginas
    }
  },
  methods: {
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarArticulos({ page: n })
    },

    mostrar() {
      var self = this
      var modal = new Modal(self.$refs.mdlSeleccionArticulo)
      modal.show()
    },

    refrescarArticulos() {
      let self = this

      iu.spinner.mostrar('#tabla-articulos')

      let params = {
        dato: self.dato,
        id_familia: self.idFamilia,
        page: self.page,
        epp: self.epp,
        orden: 'desc',
        con_familia: true,
        con_cantidades: true,
        con_unidades_medida: true,
      }

      ArticuloSrv.articulosJSON(params).then(response => {
        localStorage.setItem('argusblack.mdlSeleccionarArticulos.por_pagina', self.epp)
        let page = response.data
        self.articulos = page.data
        self.ta = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarArticulos()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-articulos')
      })
    },

    seleccionarArticulo: function(articulo) {
      var self = this
      // Carga de telefonos del cliente
      self.$emit('articulo-seleccionado', articulo)
      var modal = Modal.getInstance(self.$refs.mdlSeleccionArticulo)
      modal.hide()
    }
  }
}
</script>
