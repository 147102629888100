<template>
  <Layout tituloPagina="Inventario | Movimientos">
    <div class="row">
      <div class="col-lg-4 col-md-6 mb-3"> 
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarMovimientos()
          "
          title="Solo filtra por nombre de artículo, descripción, cantidad de entrada, cantidad de salida, total"
          :datoini="dato"
          placeholder="Dato del artículo"
        ></filtrador>
      </div>
      <div class="col-lg-4 col-md-6 mb-3">
        <div class="input-group">
          <input
            type="number"
            class="form-control"
            v-model="id_articulo"
            min="0"
            onclick="this.select()"
            oninput="this.value = Math.abs(this.value)"
            placeholder="Seleccionar o insertar él id del artículo"
            @keyup.enter="refrescarMovimientos()"
          />
          <button
            class="btn btn-warning waves-effect waves-light"
            @click="seleccionarArticulo()" :title="id_articulo == null ? 'Seleccionar' : 'Cambiar'"
          >
            {{id_articulo == null ? 'Seleccionar' : 'Cambiar'}}
          </button>
          <button class="btn btn-success waves-effect waves-light" @click="refrescarMovimientos()">
            <i class="mdi mdi-filter"></i>
          </button>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="input-group">
          <span class="input-group-text">
            <i class="mdi mdi-filter"></i>&nbsp;
            <strong>Familia</strong>
          </span>
          <select
            class="form-select"
            name="familia"
            v-model="id_familia"
            @change="refrescarMovimientos()"
          >
            <option :value="null">Todos</option>
            <option
              v-for="familia in familias"
              :key="familia.id"
              :value="familia.id"
            >
              {{familia.division}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-1">
        <div class="form-check" style="display: inline-block;">
          <input
            id="dia" type="checkbox"
            @change="checkedDia()"
            class="form-check-input form-check-input-success"
          />
          <label
            for="dia" class="form-check-label"
            :class="{ 'text-success': dia == true }"
          >
            <strong>Día</strong>
          </label>
        </div>
      </div>
      <div class="col-md-1">
        <div class="form-check" style="display: inline-block;">
          <input
            id="personalizado" type="checkbox"
            @change="checkedPersonalizado()"
            class="form-check-input form-check-input-info"
          />
          <label
            for="personalizado" class="form-check-label"
            :class="{ 'text-info': personalizado == true }"
          >
            <strong>Personalizado</strong>
          </label>
        </div>
      </div>
    </div>
    <div class="row fechas" v-if="dia == true || personalizado == true">
      <div class="offset-lg-3 col-lg-3 col-md-6">
        <label for="">Fecha inicial</label>
        <Datepicker
          style="background: white;"
          ref="fecha_inicial"
          v-model="fecha_inicial"
          placeholder="dd/mm/aaaa"
          :class="'form-control'"
          :inputFormat="'dd/MM/yyyy'"
        />
      </div>
      <div class="col-lg-3 col-md-6" v-if="dia != true || personalizado == true">
        <label for="">Fecha Final</label>
        <Datepicker
          style="background: white;"
          ref="fecha_final"
          v-model="fecha_final"
          placeholder="dd/mm/aaaa"
          :class="'form-control'"
          :inputFormat="'dd/MM/yyyy'"
          v-if="dia != true || personalizado == true"
        />
      </div>
      <div class="col-lg-3 col-md-6 pt-4">
        <button class="btn btn-secondary"
          @click="refrescarMovimientos()"
        >
        <i class="mdi mdi-refresh"></i>
        </button>
      </div>
    </div>
    <br>
      
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table id="tblListaMovimientos" class="table table-hover" style="min-width: 1400px">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Hora</th>
                <th>ID del artículo</th>
                <th>Nombre del artículo</th>
                <th style="width: 100px">Familia de artículo</th>
                <th>División</th>
                <th style="width: 90px">Cantidad inicial</th>
                <th style="width: 90px">Cantidad de entrada</th>
                <th style="width: 90px">Cantidad de salida</th>
                <th style="width: 90px">Total</th>
                <th style="width: 90px">Unidad de medida</th>
                <th style="width:210px;" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="movimiento in movimientos" :key="movimiento.id">
                <td>{{formatoFecha(movimiento.created_at)}}</td>
                <td>{{formatoHora(movimiento.created_at)}}</td>
                <td><b>{{movimiento.id_articulo}}</b></td>
                <td>
                  {{movimiento.nombre_articulo}}
                  <sup
                    v-show="movimiento.stock_inicial == 1"
                    title="Stock inicial"
                  >
                    <span class="badge badge-success">
                      Stock inicial
                    </span>
                  </sup>
                </td>
                <td class="text-right">{{movimiento.familia_articulo.familia}}</td>
                <td><b>{{movimiento.familia_articulo.division}}</b></td>
                <td class="text-right">{{movimiento.inicial}}</td>
                <td class="text-right">{{movimiento.entrada}}</td>
                <td class="text-right">{{movimiento.salida}}</td>
                <td class="text-right">
                  <strong>{{movimiento.final}}</strong>
                </td>
                <td>
                  <strong>{{movimiento.abreviatura_unidad_medida}}</strong>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'detallesMovimiento', params: { id: movimiento.id_articulo, id_unidad_medida: movimiento.articulo.id_unidad_medida } }"
                    class="btn btn-info btn-sm info"
                  >
                  <i class="mdi mdi-eye font-size-15"></i>
                    Movimientos de artículo
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarMovimientos()
        "
      ></paginador>
    </div>
    <mdl-seleccionar-articulo
      ref="seleccionarArticulo"
      v-on:articulo-seleccionado="cargarIdArticulo">
    </mdl-seleccionar-articulo>
    <br>
  </Layout>
</template>

<script>
var accounting = require('accounting/accounting.js')
import Filtrador from '@/components/Filtrador.vue'
import MovimientoArticulosSrv from '@/services/inventario/MovimientoArticulosSrv.js'
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import MdlSeleccionarArticulo from './MdlSeleccionarArticulo.vue'

import Paginador from '@/components/Paginador.vue'
import SistemaSrv from '@/services/SistemaSrv.js'
import moment from 'moment'
import Datepicker from 'vue3-datepicker'
import Layout from "@/views/layouts/main"
export default {
  name: 'ListadoMovimientos',
  components: { 
    Layout, 
    Datepicker,
    Paginador,
    Filtrador,
    MdlSeleccionarArticulo
  },
  data() {
    return {
      movimientos: [],
      familias: [],

      dato: '',
      //datos de paguinacion
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoMovimientos.por_pagina') || 10),
      tc: 0, // Total de Movimientos
      totpag: 0, // Total de páginas

      id_familia: null,
      id_articulo: null,
      
      dia: false,
      personalizado: false,

      fecha_inicial: '',
      fecha_final: ''
    }
  },
  watch: {
    fecha_inicial : function(newVal, oldVal) {
      var self = this 
      self.refrescarMovimientos()
    },
    fecha_final : function(newVal, oldVal) {
      var self = this 
      self.refrescarMovimientos()
    },
    id_articulo: function (newId){
      if(newId == '') this.id_articulo = null
      if(newId == 0) this.id_articulo = null
    }
  },
  created() {
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.epp) self.epp = parseInt(self.$route.query.epp)
    if (self.$route.query.familia) self.id_familia = parseInt(self.$route.query.familia)
    if (self.$route.query.articulo) self.id_articulo = parseInt(self.$route.query.articulo)

    
    //Carga de la fecha inicial del sistema
    if (self.fecha_inicial == '') {
      SistemaSrv.fecha({formato: 'Y-m-d'}).then(response => {
        self.fecha_inicial = new Date(moment(response.data).format())
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }

    //Carga de la fecha inicial del sistema
    if (self.fecha_final == '') {
      SistemaSrv.fecha({formato: 'Y-m-d'}).then(response => {
        self.fecha_final = new Date(moment(response.data).format())
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }

    self.cargarFamilias()
    self.refrescarMovimientos()
  },
  methods: {
    cargarFamilias: function() {
      var self = this

      let params = {
        sin_paginacion: true,
        solo_idfamdiv:true
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        let page = response.data
        self.familias = page
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarIdArticulo(articulo){
      var self = this
      self.id_articulo = articulo.id

      self.refrescarMovimientos()
    },

    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarMovimientos({ page: n })
    },

    checkedDia: function() {
      var self = this

      if(self.dia != true) self.dia = true
      else self.dia = false
      if (self.dia == true) {
        document.getElementById("dia").checked = true
      } else {
        document.getElementById("dia").checked = false
      }

      if(self.dia == true){
        document.getElementById("personalizado").checked = false
        self.personalizado = false
      }
      
      self.refrescarMovimientos()
    },

    checkedPersonalizado: function() {
      var self = this

      if(self.personalizado != true) self.personalizado = true
      else self.personalizado = false
      if (self.personalizado == true) {
        document.getElementById("personalizado").checked = true
      } else {
        document.getElementById("personalizado").checked = false
      }

      if(self.personalizado == true){
        document.getElementById("dia").checked = false
        self.dia = false
      }
      self.refrescarMovimientos()
    },

    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },

    formatoFechaMovimiento(mt){
      return moment(mt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    formatoHora(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
    },

    refrescarMovimientos: function() {
      var self = this

      iu.spinner.mostrar('#tblListaMovimientos')

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          epp: self.epp,
          familia: self.id_familia,
          articulo: self.id_articulo
        }
      }).catch(error => {})

      let params = {
        dato: self.dato,
        page: self.page,
        epp: self.epp,
        orden: 'desc',
        con_nombre_articulo: true,
        con_familia_articulo: true,
        id_familia: self.id_familia,
        id_articulo: self.id_articulo
      }

      if(self.dia && !self.personalizado){
        Object.assign(params,{
          fecha_inicial: self.formatoFechaMovimiento(self.fecha_inicial),
          fecha_final: self.formatoFechaMovimiento(self.fecha_inicial)
        })
      }

      if(self.personalizado){
        Object.assign(params,{
          fecha_inicial: self.formatoFechaMovimiento(self.fecha_inicial),
          fecha_final: self.formatoFechaMovimiento(self.fecha_final)
        })
      }
      
      MovimientoArticulosSrv.movimientosJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoMovimientos.por_pagina', self.epp)
        let page = response.data
        self.movimientos = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarMovimientos()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los movimientos de articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblListaMovimientos')
      })
    },
    seleccionarArticulo: function(){ 
      var self = this
      this.$refs.seleccionarArticulo.mostrar()
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblListaMovimientos')
  }
}
</script>

<style scoped>
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
.form-check-input-info:checked {
  background-color: #57C9EB;
  border-color: #57C9EB;
}
.pt-4 {
  padding-top: 1.8rem !important;
}
.justify-content-center{
  padding : 20px;
}
.fechas{
  padding-bottom: 20px;
}

</style>